<template>
  <v-app id="app" class="monestro" :class="'device-' + $mq">
    <Header @drawerToggle="drawerToggle()"/>
    <BaseNavigationDrawer ref="drawer"/>
    <v-main>
      <v-banner
        v-if="$auth.impersonating()"
        color="warning"
        class="impersonation"
        tile
      >
        <v-icon slot="icon">mdi-drama-masks</v-icon>
        Impersonating {{ $auth.user().first_name }} {{ $auth.user().last_name }}
        <template #actions>
          <v-btn text tile @click="unimpersonate">
            Stop impersonating
          </v-btn>
        </template>
      </v-banner>
      <NewAgreementBanner/>
      <OnboardingBanner v-if="showOnboardingBanner"/>
      <PersonalDataUpdateBanner v-else/>
      <AgeRestrictionBanner/>
      <WrongAccountBanner/>
      <DocumentExpirationBanner/>
      <ReverifyBanner/>
      <CardDepositUnavailableBanner/>
      <v-container
        :key="$route.name && $route.name === 'Onboarding' ? 'global' : $auth.user() && $auth.user().id"
        fluid
        :class="$route.name && $route.name === 'Onboarding' && 'fill-height onboarding-container heading'"
      >
        <keep-alive>
          <router-view v-if="$store.state.liveReloadingHack"/>
          <router-view v-else :key="$route.fullPath"/>
        </keep-alive>
      </v-container>
    </v-main>
    <BaseSnackbar/>
  </v-app>
</template>

<script>
import Header from "@/base/components/Header"
import NewAgreementBanner from "@/modules/documents/components/NewAgreementBanner"
import OnboardingBanner from "@/modules/users/components/OnboardingBanner"
import AgeRestrictionBanner from "@/modules/users/components/AgeRestrictionBanner"
import PersonalDataUpdateBanner from "@/modules/users/components/PersonalDataUpdateBanner"
import WrongAccountBanner from "@/modules/users/components/WrongAccountBanner"
import DocumentExpirationBanner from "@/modules/users/components/DocumentExpirationBanner"
import ReverifyBanner from "@/modules/users/components/ReverifyBanner"
import CardDepositUnavailableBanner from "@/modules/users/components/CardDepositUnavailableBanner"

import Vue from 'vue'
import { EventBus } from '@/wsupdate/eventbus'

export default Vue.extend({
  name: "App",
  components: {
    Header,
    NewAgreementBanner,
    OnboardingBanner,
    AgeRestrictionBanner,
    PersonalDataUpdateBanner,
    WrongAccountBanner,
    DocumentExpirationBanner,
    ReverifyBanner,
    // NewBankRequisitesBanner,
    CardDepositUnavailableBanner,
  },
  computed: {
    user() {
      return this.$auth.user()
    },
    showOnboardingBanner() {
      if (!this.$auth.user()) {
        return false
      }
      if (this.$route.name && (this.$route.name === 'Onboarding' || this.$route.name === 'ResetPasswordConfirm')) {
        return false
      }
      const is_verified = this.$auth.user().verification === 'approved'
      const has_deposited = this.$store.state.account.id && this.$store.state.account.is_funded
      const is_confirmed_assignment = this.$store.state.account.is_confirmed_assignment

      return !is_verified || !has_deposited || !is_confirmed_assignment
    },
  },
  watch: {
    'user.id': {
      immediate: true,
      handler(value, oldValue) {
        if (value) { // Value obtained, means we logged in.
          // Changed from user id to another (caused by impersonation)
          if (oldValue) {
            this.$closeSocket()
          }
          this.$openSocket()
        } else { // Value unset, means we logged out.
          this.$closeSocket()
        }
      },
    },
    'user.dark_mode': {
      immediate: true,
      handler() {
        this.$vuetify.theme.dark = !!this.user?.dark_mode
      },
    },
  },
  mounted() {
    EventBus.$on( 'update-balance', this.updateUser)
  },
  unmounted() {
    EventBus.$off('update-balance', this.updateUser)
  },
  created() {
    const lang = new URLSearchParams(window.location.search).get('lang')
    if (lang && lang != this.$i18n.locale && Object.keys(this.$i18n.messages).includes(lang)) {
      localStorage.setItem('locale', lang)
      this.$i18n.locale = lang
      this.$vuetify.lang.current = lang
      if (this.$auth.user()) {
        this.$http.patch(`/auth/users/me/`, { language: lang })
      }
    }
  },
  methods: {
    updateUser() {
      if (this.$auth.user()) { this.$auth.fetch() }
    },
    drawerToggle() {
      this.$refs.drawer.drawerToggle()
    },
    unimpersonate() {
      this.$auth.unimpersonate({
        makeRequest: false,
        redirect: { name: "Dashboard" },
      })
        .then(() => {
          this.$router.go("/")
        })
    },
  },
})
</script>

<style lang="scss">
.onboarding-container {
  /* XXX this container is a temporary hack, I hope to get rid of it soon. Famous last words. */
  align-items: start !important;
}

.main-container {
  h1 {
    line-height: 40px;
    text-transform: uppercase;
    font-size: 1.25rem !important;
    font-weight: 500;
    padding: 48px 0px;
  }

  h2 {
    line-height: 40px;
    text-align: left;
    text-transform: uppercase;
    font-size: 1.25rem !important;
    font-weight: 500;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif !important;
    margin-bottom: 40px;
  }

  h3 {
    color: #757c89;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    font-weight: 300;
  }

  .v-card__text {
    padding: 40px;
  }
}
@media only screen and (max-width: 812px) {
    .main-container{
        padding: 0 16px 30px 16px !important;
        .v-card__text{
          padding: 20px !important;
        }
        .v-data-table table th {
          display:none
        }
    }
}
@media print {
  .v-main {
    padding: 0 !important;
  }
}

.impersonation .v-banner__wrapper {
  border-bottom: 0 !important;
}

.impersonation .v-banner__actions {
  margin-bottom: 2px;
}

/* Disable native number input arrows */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  /* Chrome, Safari, Edge, Opera */
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  /* Firefox */
  -moz-appearance: textfield;
}

.v-snack {
  margin-top: 30px;
  &__content {
    white-space: pre-line;
  }
  &__action {
    margin-right: 8px;
  }
  a {
    color:#fff !important;
  }
}
</style>

<style lang="scss" scoped>
*, :after, :before { /* TODO Double check and delete */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
</style>
